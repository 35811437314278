import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./StyleGrid.scss";

const StyleGrid = ({
  styleGroups,
  genderFilter,
  selectedStyle,
  selectStyle,
  scrollToGroup,
}) => {
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const goToCameraScreen = (style) => {
    if (style && style.prompt) {
      navigate("/camera", { state: { selectedStyle: style } });
    } else {
      alert("Выберите стиль перед переходом!");
    }
  };

  useEffect(() => {
    if (scrollToGroup && containerRef.current) {
      const groupElement = containerRef.current.querySelector(
        `[data-group="${scrollToGroup}"]`,
      );
      if (groupElement) {
        groupElement.scrollIntoView({ behavior: "smooth", inline: "start" });
      }
    }
  }, [scrollToGroup]);

  const allStyles = styleGroups.flatMap((group) =>
    group.styles.map((style) => ({ ...style, groupName: group.groupName })),
  );

  const filteredStyles = allStyles.filter(
    (style) => genderFilter === "all" || style.gender.includes(genderFilter),
  );

  return (
    <div className="style-grid" ref={containerRef}>
      <div className="image-container scroll-snap-container">
        {filteredStyles.map((style, index) => (
          <div
            key={`${style.groupName}-${index}`}
            className={`image-card scroll-snap-item ${selectedStyle && selectedStyle.name === style.name ? "selected" : ""}`}
            onClick={() => selectStyle(style, style.groupName)}
            data-group={style.groupName}
          >
            <div
              className="image-wrapper"
              style={{ backgroundImage: `url(${style.styleImage})` }}
            ></div>
            {/* <div className="style-info"> */}
            {/*   <span className="style-name">{style.name}</span> */}
            {/*   <span className="group-name">{style.groupName}</span> */}
            {/* </div> */}
            {selectedStyle && selectedStyle.name === style.name && (
              <button
                className="camera-button"
                onClick={(e) => {
                  e.stopPropagation();
                  goToCameraScreen(style);
                }}
              >
                <span className="material-symbols-outlined">camera_alt</span>
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StyleGrid;
