import React, { createContext, useState, useContext } from "react";

const StyleContext = createContext();

export const useStyle = () => useContext(StyleContext);

export const StyleProvider = ({ children }) => {
  const [selectedStyle, setSelectedStyle] = useState(null);

  const updateSelectedStyle = (newStyle) => {
    console.log("Updating selected style:", newStyle); // Добавьте эту строку для отладки
    setSelectedStyle(newStyle);
  };

  return (
    <StyleContext.Provider value={{ selectedStyle, updateSelectedStyle }}>
      {children}
    </StyleContext.Provider>
  );
};
