// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: flex;
  background: #000000;
  flex-direction: column;
}

.cards {
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/1-ImageSelection/ImageSelectionScreen.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBCCW;EDAX,sBAAA;AADF;;AAIA;EAEE,UAAA;AAFF","sourcesContent":["@import \"../../styles/colors\";\n\n.app {\n  display: flex;\n  background: $background;\n  flex-direction: column;\n}\n\n.cards {\n  // padding-top: 160px;\n  z-index: 1;\n}\n","// _colors.scss\n$h2: white;\n\n$primary-color: #007aff; \n$border-color: #ffffff10; \n$background: #000000;\n$panel-background: #141414;\n$font-color: white;\n\n$card-background: rgba(255, 255, 255, 0.04);\n$card-background-selected: white;\n$card-border-selected: #103b44;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
