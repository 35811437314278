import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopMenuBar from "./components/TopMenuBar";
import ImageSelectionScreen from "./pages/1-ImageSelection/ImageSelectionScreen";
import CameraScreen from "./pages/2-Camera/CameraScreen";
import ResultsScreen from "./pages/3-Result/ResultsScreen";
import { StyleProvider } from "./utils/StyleContext";
import BrowserHistoryController from "./BrowserHistoryController";
import "./styles/App.scss";

const App = () => {
  return (
    <StyleProvider>
      <Router>
        <BrowserHistoryController />
        <div className="App">
          {/* <TopMenuBar /> */}
          <Routes>
            <Route path="/" element={<ImageSelectionScreen />} />
            <Route path="/camera" element={<CameraScreen />} />
            <Route path="/results" element={<ResultsScreen />} />
          </Routes>
        </div>
      </Router>
    </StyleProvider>
  );
};

export default App;
