import React, { useState, useEffect, useRef } from "react";
import { useStyle } from "../../utils/StyleContext";
import { useNavigate } from "react-router-dom";
import "./ImageSelectionScreen.scss";
import StyleGroupsMenu from "../../components/1-ImageSelection/StyleGroupPanel";
import TopMenu from "../../components/1-ImageSelection/TopMenu";
import StyleGrid from "../../components/1-ImageSelection/StyleGrid";

const apiServer =
  process.env.REACT_APP_API_SERVER || "http://192.168.1.174:8888";

const ImageSelectionScreen = () => {
  const { selectedStyle, updateSelectedStyle } = useStyle();
  const [currentVisibleGroup, setCurrentVisibleGroup] = useState("");
  const [genderFilter, setGenderFilter] = useState("all");
  const [visibleGroups, setVisibleGroups] = useState({});
  const [styleGroups, setStyleGroups] = useState([]);
  const [frameCount, setFrameCount] = useState(0);
  const [scrollToGroup, setScrollToGroup] = useState(null);
  const navigate = useNavigate();
  const groupRefs = useRef({});
  const observerRef = useRef(null);

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  useEffect(() => {
    const fetchStylesData = async () => {
      try {
        const response = await fetch(`${apiServer}/get-styles`);
        console.log("Response status:", response.status);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Raw data from server:", data);

        if (!Array.isArray(data) || data.length === 0) {
          console.error("Invalid data structure. Expected non-empty array.");
          console.log("Received data type:", typeof data);
          console.log("Data:", JSON.stringify(data, null, 2));
          throw new Error("Invalid data structure");
        }

        if (!data[0].styles || !Array.isArray(data[0].styles)) {
          console.error(
            "Invalid group structure. Expected 'styles' array in each group.",
          );
          console.log("First group:", JSON.stringify(data[0], null, 2));
          throw new Error("Invalid group structure");
        }

        const processedData = data.map((group) => {
          const shuffledStyles = shuffleArray(group.styles);
          return {
            ...group,
            styles: shuffledStyles,
            categoryImage: shuffledStyles[0]?.styleImage || null,
          };
        });

        setStyleGroups(processedData);

        localStorage.setItem("styleGroups", JSON.stringify(processedData));
      } catch (error) {
        console.error("Error in fetchStylesData:", error);
        console.error("Error stack:", error.stack);
        alert(`Произошла ошибка при загрузке стилей: ${error.message}`);
      }
    };

    fetchStylesData();
  }, []);

  useEffect(() => {
    const updateFrameCount = () => {
      const count = parseInt(localStorage.getItem("frameCount") || "0");
      if (count !== frameCount) {
        setFrameCount(count);
      }
    };

    updateFrameCount();

    const intervalId = setInterval(updateFrameCount, 1000);

    return () => clearInterval(intervalId);
  }, [frameCount]);

  useEffect(() => {
    console.log("styleGroups updated:", styleGroups);
  }, [styleGroups]);

  useEffect(() => {
    if (!styleGroups || styleGroups.length === 0) return;

    const newVisibleGroups = {};
    styleGroups.forEach((group) => {
      if (group && group.styles) {
        const hasVisibleStyles = group.styles.some(
          (style) =>
            genderFilter === "all" ||
            (style.gender && style.gender.includes(genderFilter)),
        );
        newVisibleGroups[group.groupName] = hasVisibleStyles;
      }
    });
    console.log("New visible groups:", newVisibleGroups);
    setVisibleGroups(newVisibleGroups);
  }, [genderFilter, styleGroups]);

  const setupIntersectionObserver = () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentVisibleGroup(entry.target.id);
        }
      });
    }, options);

    Object.values(groupRefs.current).forEach((ref) => {
      if (ref) observerRef.current.observe(ref);
    });
  };

  useEffect(() => {
    setupIntersectionObserver();
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [styleGroups, visibleGroups]);

  const CurrentTimeAndFrameCount = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
      const timerId = setInterval(() => setTime(new Date()), 1000);
      return () => clearInterval(timerId);
    }, []);

    const timeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    return (
      <div className="time-and-frame-count">
        <div>{time.toLocaleTimeString("ru-RU", timeFormatOptions)}</div>
        <span className="material-symbols-outlined">add_a_photo</span>{" "}
        {frameCount}
      </div>
    );
  };

  const handleFilterChange = (gender) => {
    setGenderFilter(gender);
  };

  const selectStyle = (style, groupName) => {
    console.log("Selecting style:", style, "from group:", groupName);
    updateSelectedStyle({
      ...style,
      groupName: groupName,
      styleImage: style.styleImage,
    });
    setCurrentVisibleGroup(groupName);
  };

  const goToCameraScreen = () => {
    if (selectedStyle && selectedStyle.prompt) {
      navigate("/camera", { state: { selectedStyle: selectedStyle } });
    } else {
      alert("Выберите стиль перед переходом!");
    }
  };

  const handleScrollToGroup = (groupName) => {
    setScrollToGroup(groupName);
    setTimeout(() => setScrollToGroup(null), 100);
  };

  const scrollToSelectedStyle = () => {
    console.log("Attempting to scroll to selected style:", selectedStyle);
    if (selectedStyle && selectedStyle.groupName) {
      handleScrollToGroup(selectedStyle.groupName);
    } else {
      console.log("No style is currently selected or missing groupName");
    }
  };

  useEffect(() => {
    if (
      styleGroups.length > 0 &&
      styleGroups[0].styles &&
      styleGroups[0].styles.length > 0
    ) {
      const firstStyle = styleGroups[0].styles[0];
      if (
        firstStyle &&
        firstStyle.styleImage &&
        typeof firstStyle.styleImage === "string"
      ) {
        console.log(
          "Sample image data:",
          firstStyle.styleImage.substring(0, 100) + "...",
        );
      } else {
        console.log("First style image is not a string:", firstStyle);
      }
    } else {
      console.log("No styles available in the first group");
    }
  }, [styleGroups]);

  useEffect(() => {
    console.log("Selected style updated:", selectedStyle);
  }, [selectedStyle]);

  return (
    <div className="app">
      <StyleGroupsMenu
        styleGroups={styleGroups}
        currentVisibleGroup={currentVisibleGroup}
        genderFilter={genderFilter}
        scrollToGroup={handleScrollToGroup}
      />
      <div className="gradient"></div>
      <div className="cards">
        <TopMenu
          genderFilter={genderFilter}
          handleFilterChange={handleFilterChange}
          selectedStyle={selectedStyle}
          scrollToSelectedStyle={scrollToSelectedStyle}
        />
        <StyleGrid
          styleGroups={styleGroups}
          genderFilter={genderFilter}
          selectedStyle={selectedStyle}
          selectStyle={selectStyle}
          scrollToGroup={scrollToGroup}
        />
      </div>
    </div>
  );
};

export default ImageSelectionScreen;
