// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frameOverlay {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
}
.frameOverlay svg {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/FrameComponent.scss"],"names":[],"mappings":"AAQA;EACE,oBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;AAPF;AASE;EACE,WAAA;EACA,YAAA;AAPJ","sourcesContent":["//html, body {\n//  margin: 0;\n//  padding: 0;\n//  width: 100%;\n//  height: 100%;\n//  overflow: auto; // Предотвращаем прокрутку страницы\n//}\n\n.frameOverlay {\n  pointer-events: none; // Отключаем взаимодействие указателя с этим элементом\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n  z-index: 1000;\n\n  svg {\n    width: 100%;\n    height: 100%;\n\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
