import React, { useState, useEffect } from "react";
import { useStyle } from "./StyleContext";
import { useLocation } from "react-router-dom";
// const apiServer = process.env.REACT_APP_API_SERVER;

const apiServer = process.env.REACT_APP_API_SERVER || "http://127.0.0.1:8888";

console.log(apiServer);

const resizeAndConvertToWebP = (base64Image, maxWidth) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const scaleFactor = maxWidth / img.width;
      canvas.width = maxWidth;
      canvas.height = img.height * scaleFactor;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const webpImage = canvas.toDataURL("image/webp", 0.95);
      resolve(webpImage);
    };
    img.onerror = reject;
    img.src = base64Image;
  });
};
const sendImageToServer = async (base64Image, selectedStyle, apiToken) => {
  try {
    if (!base64Image) {
      throw new Error("base64Image is undefined");
    }

    if (
      !selectedStyle ||
      (!selectedStyle.styleImage && !selectedStyle.image) ||
      !selectedStyle.prompt
    ) {
      console.error("Invalid selectedStyle:", selectedStyle);
      throw new Error("selectedStyle is invalid or incomplete");
    }

    const cleanedImage = base64Image.replace(/^data:image\/[a-z]+;base64,/, "");
    const styleImageUrl = selectedStyle.styleImage || selectedStyle.image;

    const response = await fetch(`${apiServer}/generate-image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": apiToken,
      },
      body: JSON.stringify({
        selected_prompt: selectedStyle.prompt,
        cleaned_image: cleanedImage,
        source_image: styleImageUrl,
        save_previews: true,
      }),
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log("Request succeeded with response:", responseData);
      return { promptId: responseData.promptId };
    } else {
      console.error("Request failed with status:", response.status);
      const errorText = await response.text();
      console.error("Error details:", errorText);
      return null;
    }
  } catch (error) {
    console.error("Error in sendImageToServer:", error);
    return null;
  }
};

export { resizeAndConvertToWebP, sendImageToServer };
