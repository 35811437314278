import React from 'react';
import { useLocation } from 'react-router-dom';
import './TopMenuBar.scss';

const TopMenuBar = () => {
    const location = useLocation();

    // Функция для определения активной вкладки
    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div className="top-menu-bar">

            {/*<div className={isActive('/') ? 'step active' : 'step'}>1</div>*/}
            {/*<div className={isActive('/camera') ? 'step active' : 'step'}>2</div>*/}
            {/*<div className={isActive('/results') ? 'step active' : 'step'}>3</div>*/}
            {/* Другие индикаторы шагов */}

            
        </div>
    );
};

export default TopMenuBar;
