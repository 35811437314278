import { useRef, useEffect } from 'react';

const useCamera = (videoRef, canvasRef, setImage) => {
    // const videoRef = useRef(null);
    // const canvasRef = useRef(null);

    const initCamera = () => {
        const videoElement = videoRef.current;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: 1920 }, // Желаемая ширина видео
                    height: { ideal: 1080 }  // Желаемая высота видео
                }
            })
                .then(stream => {
                    videoElement.srcObject = stream;
                    videoElement.onloadeddata = () => {
                        videoElement.play().catch(err => console.error("Ошибка воспроизведения видео:", err));
                    };
                })
                .catch(err => console.error("Ошибка при получении доступа к камере:", err));
        }
    };


    const restartVideoStream = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }
        initCamera();
    };

    useEffect(() => {
        initCamera();
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    const captureImage = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;

        // Проверяем, существуют ли canvas и video, и что video содержит поток
        if (!canvas || !video || !video.srcObject) {
            console.error("Video or canvas element is not ready.");
            return;
        }

        // Теперь безопасно устанавливаем размеры холста
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext('2d');
        // Убедитесь, что контекст был успешно получен
        if (context) {
            // Очистка холста перед отрисовкой нового изображения
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Отрисовка видео на холсте
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageUrl = canvas.toDataURL('image/png');
            setImage(imageUrl);
        } else {
            console.error("Failed to get canvas context.");
        }
    };

    return { videoRef, canvasRef, captureImage, restartVideoStream };
};

export default useCamera;
