// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.top-menu-bar {
  display: none;
  opacity: 0;
  margin-left: 330px;
  margin-right: 446px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  position: fixed; /* Изменено на fixed */
  bottom: 28px; /* Размещаем меню в верхней части экрана */
  left: 0; /* Устанавливаем меню в левую сторону экрана */
  right: 0; /* Устанавливаем меню в правую сторону экрана */
  z-index: 1000; /* Убедитесь, что меню находится над другими элементами */
  /*opacity: 0.8;*/
  background: rgba(200, 200, 200, 0.6);
  -webkit-backdrop-filter: blur(19.0499992371px);
          backdrop-filter: blur(19.0499992371px);
  border-radius: 30px;
}

.top-menu-bar .step {
  font-size: 16;
  width: 20px;
  height: 20px;
  line-height: 3vw;
  color: rgb(161, 161, 161);
  border-radius: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.top-menu-bar .step.active {
  color: black;
  background-color: #ADFF00;
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/TopMenuBar.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,aAAA;EACA,UAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EAEA,eAAA,EAAA,sBAAA;EACA,YAAA,EAAA,0CAAA;EACA,OAAA,EAAA,8CAAA;EACA,QAAA,EAAA,+CAAA;EACA,aAAA,EAAA,yDAAA;EACA,gBAAA;EACA,oCAAA;EACA,8CAAA;UAAA,sCAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;EACA,mBAAA;AACJ","sourcesContent":[".top-menu-bar {\n    display: none;\n    opacity: 0;\n    margin-left: 330px;\n    margin-right: 446px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100px;\n    //background: #ffffff;\n    position: fixed; /* Изменено на fixed */\n    bottom: 28px; /* Размещаем меню в верхней части экрана */\n    left: 0; /* Устанавливаем меню в левую сторону экрана */\n    right: 0; /* Устанавливаем меню в правую сторону экрана */\n    z-index: 1000; /* Убедитесь, что меню находится над другими элементами */\n    /*opacity: 0.8;*/\n    background: rgba(200, 200, 200, 0.60);\n    backdrop-filter: blur(19.049999237060547px);\n    border-radius: 30px;\n}\n\n.top-menu-bar .step {\n    font-size: 16;\n    width: 20px;\n    height: 20px;\n    line-height: 3vw;\n    color: rgb(161, 161, 161);\n    border-radius: 80%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 0 5px;\n}\n\n.top-menu-bar .step.active {\n    color: black;\n    background-color: #ADFF00;\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
