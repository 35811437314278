import React, { useState, useEffect, useRef } from "react";
import { Settings } from "lucide-react";
import "./TopMenu.scss";

const Popover = ({ children, isOpen, onClose }) => {
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="popover" ref={popoverRef}>
      <div className="popover-content bubble">{children}</div>
    </div>
  );
};

const TopMenu = ({
  genderFilter,
  handleFilterChange,
  selectedStyle,
  scrollToSelectedStyle,
  onSettingsClick,
  logoImage,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handleButtonClick = (filter) => {
    if (genderFilter === filter) {
      handleFilterChange("all");
    } else {
      handleFilterChange(filter);
    }
  };

  return (
    <div className="top-menu-wrapper">
      <div className="top-menu">
        <div className="left-section">
          <div
            className="selected-style"
            onClick={selectedStyle ? scrollToSelectedStyle : undefined}
            title={selectedStyle ? selectedStyle.name : "Выберите стиль"}
          >
            <img
              src={selectedStyle ? selectedStyle.styleImage : logoImage}
              alt={selectedStyle ? selectedStyle.name : "Логотип"}
              className="selected-style-image"
            />
            <div className="selected-style-info">
              {selectedStyle ? (
                <>
                  <span className="selected-style-prefix">
                    Портрет в стиле:
                  </span>
                  <span className="selected-style-group">
                    {selectedStyle.groupName.charAt(0).toUpperCase() +
                      selectedStyle.groupName.slice(1).toLowerCase()}
                  </span>
                </>
              ) : (
                <span className="selected-style-group">
                  Выберите стиль:
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="middle-section">{/* Пустое пространство */}</div>
        <div className="right-section">
          <div className="filter-controls">
            <a
              href="#"
              onClick={() => handleButtonClick("male")}
              className={`filter-button male ${genderFilter === "male" ? "active" : ""}`}
            >
              👨 Мужские
            </a>
            <a
              href="#"
              onClick={() => handleButtonClick("female")}
              className={`filter-button female ${genderFilter === "female" ? "active" : ""}`}
            >
              👩 Женские
            </a>
            <a
              href="#"
              onClick={() => handleButtonClick("kids")}
              className={`filter-button kids ${genderFilter === "kids" ? "active" : ""}`}
            >
              👶 Детские
            </a>
          </div>
          <button
            className="settings-button"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            style={{ position: 'relative' }}
          >
            <Settings size={24} />
          </button>
          <Popover
            isOpen={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
          >
            <ul className="popover-menu">
              <li
                onClick={() => {
                  onSettingsClick();
                  setIsPopoverOpen(false);
                }}
              >
                Настройки
              </li>
              <li
                onClick={() => {
                  /* Добавьте здесь нужное действие */ setIsPopoverOpen(false);
                }}
              >
                Пункт меню 1
              </li>
              <li
                onClick={() => {
                  /* Добавьте здесь нужное действие */ setIsPopoverOpen(false);
                }}
              >
                Пункт меню 2
              </li>
            </ul>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
