import React from 'react';
import './FrameComponent.scss'; // Импорт файла SCSS стилей

const FrameComponent = () => {
    // Переменные для отступов
    // Отступы по всем краям

    const marginTop = 5; // Отступ сверху в пикселях
    const marginRight = 9; // Отступ справа
    const marginBottom = 20; // Отступ снизу
    const marginLeft = 14; // Отступ слева

// Для SVG используйте vw и vh для ширины и высоты, чтобы они были относительными к размеру вьюпорта
    const windowWidth = `calc(100vw - ${marginLeft + marginRight}px)`; // Ширина "окна" будет равна 100vw, поскольку marginLeft и marginRight равны 0
    const windowHeight = `calc(100vh - ${marginTop + marginBottom}px)`; // Высота "окна" уменьшена на высоту верхнего отступа

// Позиционирование "окна"
    const windowOffsetX = marginLeft; // Смещение "окна" по горизонтали
    const windowOffsetY = marginTop; // Смещение "окна" по вертикали




    return (
        <div className="frameOverlay">
            <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <mask id="hole">
                        <rect width="100%" height="100%" fill="white"/>
                        {/* Убран transform, чтобы корректно позиционировать прямоугольник */}
                        <rect x={windowOffsetX + "px"} y={windowOffsetY + "px"} width={windowWidth} height={windowHeight} fill="black" rx="38px"/>
                    </mask>
                </defs>
                <rect width="100%" height="100%" fill="black" mask="url(#hole)" />
            </svg>
        </div>
    );

}

export default FrameComponent;
