import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  sendImageToServer,
  resizeAndConvertToWebP,
} from "../../utils/ImageUploader";
import { useStyle } from "../../utils/StyleContext";
import "./ResultsScreen.scss";
import FrameComponent from "../../components/FrameComponent";
import axios from "axios";
// const apiServer = process.env.REACT_APP_API_SERVER;
const apiServer = process.env.REACT_APP_API_SERVER || "http://127.0.0.1:8888";
const apiToken = "ksdjhfgkljadhbsfijlgnaipudshfrgiopm";

const ResultsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedStyle } = useStyle();
  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const capturedImage = location.state?.capturedImage;
  const validImages = Array.isArray(images) ? images : [];
  const [styleDetails, setStyleDetails] = useState(null);
  const intervalId = useRef(null);
  const [promptId, setPromptId] = useState(null);
  const [isPrintButtonDisabled, setIsPrintButtonDisabled] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedStyle) {
      setStyleDetails({
        prompt: selectedStyle.prompt,
        image: selectedStyle.styleImage || selectedStyle.image,
      });
    }
  }, [selectedStyle]);

  useEffect(() => {
    if (capturedImage && styleDetails) {
      generateImage();
    }
  }, [capturedImage, styleDetails]);

  const generateImage = async () => {
    setShowProgressBar(true);
    setProgress(0);
    setError(null);
    try {
      const resizedImage = await resizeAndConvertToWebP(capturedImage, 1024);
      const response = await sendImageToServer(
        resizedImage,
        styleDetails,
        apiToken,
      );

      if (response && response.promptId) {
        setPromptId(response.promptId);
        startPolling(response.promptId);
      } else {
        throw new Error("Failed to generate image. Please try again.");
      }
    } catch (error) {
      console.error("Error in generateImage:", error);
      setError(
        "An error occurred while generating the image. Please try again.",
      );
      setShowProgressBar(false);
    }
  };

  const startPolling = (promptId) => {
    let progressValue = 0;
    intervalId.current = setInterval(() => {
      progressValue += 2;
      if (progressValue > 100) {
        progressValue = 0;
      }
      setProgress(progressValue);
    }, 200);

    const pollInterval = setInterval(async () => {
      try {
        const response = await axios.get(`${apiServer}/get-image/${promptId}`, {
          headers: { "X-API-Key": apiToken },
        });

        if (
          response.data &&
          response.data.base64Images &&
          response.data.base64Images.length > 0
        ) {
          setImages(response.data.base64Images);
          setShowProgressBar(false);
          setIsPrintButtonDisabled(false);
          clearInterval(pollInterval);
          clearInterval(intervalId.current);
        }
      } catch (error) {
        if (error.response && error.response.status !== 404) {
          console.error("Error polling for image:", error);
          setError("Failed to retrieve the generated image. Please try again.");
          setShowProgressBar(false);
          clearInterval(pollInterval);
          clearInterval(intervalId.current);
        }
      }
    }, 2000); // Poll every 2 seconds
  };

  const handlePrint = async () => {
    if (!promptId) return;

    setIsPrintButtonDisabled(true);
    try {
      const response = await axios.post(
        `${apiServer}/print/${promptId}`,
        null,
        {
          headers: { "X-API-Key": apiToken },
        },
      );
      console.log("Print request successful:", response.data);

      const currentCount = parseInt(localStorage.getItem("frameCount") || "0");
      localStorage.setItem("frameCount", (currentCount + 1).toString());

      navigate("/");
    } catch (error) {
      setError(
        "Error printing: " + (error.response?.data.detail || error.message),
      );
      setIsPrintButtonDisabled(false);
    }
  };

  const handleRetry = () => {
    setImages([]);
    setPromptId(null);
    setError(null);
    setIsPrintButtonDisabled(true);
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    generateImage();
  };

  return (
    <div>
      <FrameComponent />
      <div className="result-container">
        {error && <div className="error-message">{error}</div>}
        <div className="results-grid">
          {showProgressBar && (
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          )}
          {validImages.length > 0 && <img src={validImages[0]} alt="Result" />}
        </div>
        <button
          className="next-button"
          onClick={handlePrint}
          disabled={validImages.length === 0 || isPrintButtonDisabled}
        >
          Печать фото
        </button>
        <button
          className="retry-button"
          onClick={handleRetry}
          disabled={showProgressBar}
        >
          ⟳
        </button>
        <button
          className="back-button"
          onClick={() => navigate("/")}
          disabled={showProgressBar}
        >
          Начать сначала
        </button>
      </div>
    </div>
  );
};

export default ResultsScreen;
