import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const BrowserHistoryController = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePopState = (event) => {
      // Prevent the default action
      event.preventDefault();

      // Push the current state back to the history
      // This effectively cancels the back/forward navigation
      navigate(location.pathname, { replace: true });
    };

    // Add event listener
    window.addEventListener("popstate", handlePopState);

    // Push the initial state
    window.history.pushState(null, "", location.pathname);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location, navigate]);

  return null;
};

export default BrowserHistoryController;
