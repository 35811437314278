import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./CameraScreen.scss";
import useCamera from "../../utils/useCamera";
import FrameComponent from "../../components/FrameComponent";
import faceSvg from "../../assets/face.svg";

const CameraScreen = () => {
  const [image, setImage] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const backgroundVideoRef = useRef(null);
  const canvasRef = useRef(null);
  const { captureImage, restartVideoStream } = useCamera(
    videoRef,
    canvasRef,
    setImage,
  );
  const [showWhiteScreen, setShowWhiteScreen] = useState(false);

  useEffect(() => {
    if (countdown > 0 && countdown <= 3) {
      const blinkInterval = setInterval(() => {
        const container = document.querySelector(".parent-container");
        if (container) {
          container.classList.toggle("blink-border");
        }
      }, 400);

      return () => clearInterval(blinkInterval);
    }
  }, [countdown]);

  const startCaptureWithCountdown = () => {
    let count = 3;
    setCountdown(count);
    const timer = setInterval(() => {
      count -= 1;
      setCountdown(count);

      if (count === 0) {
        clearInterval(timer);
        setShowWhiteScreen(true);
        setTimeout(() => {
          setShowWhiteScreen(false);
          captureImage();
          setCountdown(null);
        }, 800);
      }
    }, 800);
  };

  const handleSendButtonClick = () => {
    if (image) {
      navigate("/results", { state: { capturedImage: image } });
    } else {
      console.error("Image not captured");
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDeleteClick = () => {
    setImage(null);
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  return (
    <div>
      <FrameComponent />
      {showWhiteScreen && <div className="white-screen"></div>}
      <div className="blurred-background">
        <video ref={backgroundVideoRef} autoPlay muted loop></video>
      </div>
      <div className="content">
        <div className="parent-container">
          <video ref={videoRef} className="videoInsert" autoPlay muted></video>
          {image && (
            <img
              src={image}
              alt="Захваченное изображение"
              className="captured-image"
            />
          )}
          {countdown !== null && <div className="countdown">{countdown}</div>}
          <canvas
            ref={canvasRef}
            className={image ? "canvas-hidden" : ""}
          ></canvas>

          <button
            className="capture-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              image ? handleDeleteClick() : startCaptureWithCountdown();
            }}
          >
            {image ? (
              "❌"
            ) : (
              <svg
                className="svg-top-margin"
                width="40"
                height="33"
                viewBox="0 0 40 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_761_440)">
                  <path
                    d="M40 9.30851V29.159C39.8199 29.6636 39.7091 30.1964 39.446 30.659C38.5457 32.2992 37.1053 33.0141 35.277 33.0141C25.1662 33.0141 15.0416 33.0141 4.93075 33.0141C4.61219 33.0141 4.27978 33.0001 3.96122 32.9721C1.8144 32.7758 0.0138504 30.8552 0 28.6823C0 22.3879 0 16.0795 0 9.78514C0 7.58421 1.88366 5.60757 4.04432 5.52346C5.81717 5.45337 7.59003 5.49542 9.34903 5.4814C9.50139 5.4814 9.65374 5.4814 9.8338 5.4814C9.8338 5.00477 9.8338 4.57019 9.8338 4.13561C9.8338 1.75243 11.482 0.0281309 13.8366 0.0141122C17.9363 9.35035e-05 22.036 -0.0139252 26.1357 0.0141122C28.4765 0.0141122 30.1108 1.75243 30.1247 4.10757C30.1247 4.55617 30.1247 4.99075 30.1247 5.46738C30.3186 5.46738 30.4571 5.46738 30.5956 5.46738C32.1884 5.46738 33.7673 5.46738 35.3601 5.46738C37.1607 5.46738 38.5596 6.22439 39.4321 7.83654C39.6814 8.28514 39.7922 8.80383 39.9723 9.29449L40 9.30851ZM19.9862 29.1029C25.3324 29.1309 29.7507 24.687 29.7645 19.2478C29.7922 13.8365 25.4017 9.3786 20.0139 9.36458C14.6537 9.35056 10.2216 13.7664 10.2355 19.2197C10.2355 24.7571 14.6537 29.1029 19.9723 29.1029H19.9862Z"
                    fill="black"
                  />
                  <path
                    d="M19.9724 25.7664C16.4128 25.7523 13.4904 22.7804 13.5319 19.2196C13.5735 15.5888 16.4682 12.7009 20.0555 12.715C23.5735 12.729 26.4821 15.715 26.4544 19.2617C26.4267 22.8785 23.5181 25.7804 19.9724 25.7664Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_761_440">
                    <rect width="40" height="33" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </button>
        </div>

        <div>
          <button className="back-button" onClick={handleBackClick}>
            Назад
          </button>
          <button
            className="next-button"
            onClick={handleSendButtonClick}
            disabled={!image}
          >
            Отправить
          </button>
        </div>
      </div>
      //{" "}
      <div className="face-overlay">
        // <img src={faceSvg} alt="Face Overlay" />
        //{" "}
      </div>
    </div>
  );
};

export default CameraScreen;
